import React from "react"
import Img from "gatsby-image"
import { graphql } from "gatsby"
import { Link } from "gatsby"

import Layout from "../../components/layout/layout"
import SEO from "../../components/seo"

import appStoreBadge from "../../images/app-store-badge.svg"

const BlinkDetail = ({ data }) => {
  return (
    <Layout pageHeaderText="Blink" pageHeaderImage="forest.png">
      <SEO title="Blink" />
      <h2
        style={{
          textAlign: "center",
          fontSize: "3em",
          marginTop: "1rem",
          marginBottom: "2rem",
        }}
      >
        Blink: A Bug's Light
      </h2>
      <p style={{ textAlign: "center", maxWidth: "960px", margin: "0 auto" }}>
        My first mobile game! Fly around a dark forest as a firefly at dusk and
        blink your bioluminescent light at the right locations to keep the
        forest lit up.
      </p>

      <a
      style={{display: "block", textAlign:"center", marginTop:"2rem", marginBottom:"2rem", fontWeight: "700", fontSize: "1.5rem" }}
      href="https://apps.apple.com/us/app/blink-a-bugs-light/id1475168017"
      target="_blank"
      rel="noopener noreferrer"
      >
        Blink for iOS
      </a>
      <a
        style={{display: "block", textAlign:"center", marginTop:"2rem", marginBottom:"3rem", fontWeight: "700", fontSize: "1.5rem" }}
        href="https://play.google.com/store/apps/details?id=com.devduck.blink"
        target="_blank"
        rel="noopener noreferrer"
      >
        Blink for Android
      </a>

      <p style={{ marginTop:"2rem", textAlign: "center", maxWidth: "960px" }}>
        Follow along with the development of this game from the very beginning on <a href="https://www.youtube.com/watch?v=3rO_KLy0IMM&list=PLMnxmWR1zE8d6hiyJnSZ3XHNigS37hMM2" target="_blank" rel="noopener noreferrer">my YouTube channel</a>!
      </p>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "1fr 1fr",
          gridTemplateRows: "auto auto",
          gridColumnGap: "1rem",
          gridRowGap: "2rem",
          marginBottom: "1rem",
          marginTop: "2rem",
        }}
      >
        <Img style={{gridColumnStart:"1", gridColumnEnd:"3"}} fluid={data.gameplayImage.childImageSharp.fluid} />
        <Img fluid={data.unlocksImage.childImageSharp.fluid} />
        <Img fluid={data.environmentsImage.childImageSharp.fluid} />
      </div>
      <Link style={{display:"block", textAlign:"right"}}to="/apps/blink/privacy-policy">Privacy Policy</Link>
      <Link style={{display:"block", textAlign:"right"}}to="/apps/blink/terms-and-conditions">Terms and Conditions</Link>
    </Layout>
  )
}

export default BlinkDetail

export const query = graphql`
  query {
    gameplayImage: file(relativePath: { eq: "blink-gameplay.png" }) {
      childImageSharp {
        fluid(maxWidth: 960) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    unlocksImage: file(relativePath: { eq: "blink-unlocks.png" }) {
      childImageSharp {
        fluid(maxWidth: 960) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    environmentsImage: file(relativePath: { eq: "blink-environments.png" }) {
      childImageSharp {
        fluid(maxWidth: 960) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
